import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { isEqual } from 'lodash'
import { useForm } from 'hooks'

import withReduxDataFetch from 'components/HOC/redux-data-fetch'

import { useSelector } from 'react-redux'
import { generatePassword, appendObjectToFormData } from 'utils/helpers'
import { rolesCall } from 'store/general/actions'
import { rolesSelector } from 'store/general/selectors'
import { useTranslation } from 'react-i18next'
import {
  Loader,
  Dialog,
  ImageSelect,
  NoteBar,
  // useTranslation,
  Divider,
  Button,
  Icon
} from 'components/common'
import validationSchema from './validation-schema'

const UserAddEdit = ({
  moduleName,
  accountId,
  isOpen,
  close,
  userData,
  createAction,
  editAction,
  isLoading,
  isStep
}) => {
  const isNew = !userData
  const { t } = useTranslation()
  const { data: roles } = useSelector(
    (store) => rolesSelector(store, moduleName ?? 'company'),
    isEqual
  )
  const [isPassword, setIsPassword] = useState(true)
  const [isConfirmPassword, setIsConfirmPassword] = useState(true)

  const [profilePicture, setProfilePicture] = useState({
    src: null,
    url: userData?.image || ''
  })

  const defaultValues = useMemo(() => {
    if (isNew) {
      const defaultRole = roles.find((role) => role.default === 1)
      return {
        full_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        job_title: '',
        role: defaultRole.name || '',
        telephone: '',
        mobile: '',
        add_to_contact_book: false,
        isNew: true,
        accountId: Boolean(accountId)
      }
    }

    const {
      email,
      role,
      user_detail: { full_name, job_title, mobile, telephone }
    } = userData

    return {
      full_name,
      email,
      password: '',
      password_confirmation: '',
      job_title: job_title || '',
      role: role[0]?.name,
      mobile: mobile || '',
      telephone: telephone || '',
      add_to_contact_book: false,
      isNew: false,
      accountId: Boolean(accountId)
    }
  }, [])

  const {
    setValue,
    FormField,
    handleSubmit,
    formState: { isDirty, errors }
  } = useForm({
    validationSchema,
    defaultValues,
    isLoading
  })
  const generateAndDisplayPassword = useCallback(() => {
    const psw = generatePassword(8)
    setValue('password', psw, {
      shouldDirty: true,
      shouldValidate: true
    })
    setIsPassword(false)
  }, [])

  const handleNewImage = useCallback((newImage) => {
    setProfilePicture(newImage)
  }, [])

  const submitForm = useCallback(
    async (data) => {
      const { full_name, telephone, mobile, job_title, add_to_contact_book, ...filteredData } = data

      const userDetail = {
        userDetail: {
          full_name,
          job_title,
          mobile,
          telephone
        }
      }

      const finalData = isNew
        ? {
            ...userDetail,
            ...filteredData,
            ...(!isStep
              ? {
                  account_id: accountId,
                  add_to_contact_book: add_to_contact_book ? 1 : 0
                }
              : {
                  step: 'user',
                  method: '',
                  index: ''
                })
          }
        : {
            ...userDetail,
            ...filteredData,
            ...(!isStep
              ? {
                  account_id: userData.account_id
                }
              : {
                  step: 'user',
                  method: userData.method,
                  index: String(userData.index)
                })
          }

      const formData = appendObjectToFormData(finalData)
      profilePicture.src && formData.append('image', profilePicture.src)

      isNew
        ? createAction(formData)
        : isStep
        ? editAction(formData, { method: userData.method, index: userData.index })
        : editAction(formData, userData.id)
    },
    [isNew, profilePicture.src]
  )

  const togglePasswordState = useCallback(() => {
    setIsPassword((prevState) => !prevState)
  }, [])
  const toggleConfirmPasswordState = useCallback(() => {
    setIsConfirmPassword((prevState) => !prevState)
  }, [])

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="right"
      title={isNew ? t('Create User') : t('Edit User')}
      disableBackdropClick
      actions={[
        {
          text: t('Cancel'),
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: isNew ? t('Create User') : t('Edit User'),
          buttonClassname: 'success',
          variant: 'primary',
          onClick: handleSubmit(submitForm)
        }
      ]}
    >
      {isLoading && <Loader linear />}
      <div className="user-modal container pt-lg pb-xxs px-lg">
        <form id="user-form" autoComplete="off">
          {!isStep && (
            <>
              <div className="field">
                <ImageSelect
                  label={t('Profile Picture')}
                  initialImageUrl={profilePicture.url}
                  onChange={handleNewImage}
                  config={{
                    extensions: ['jpg', 'jpeg', 'png'],
                    maxSizeMB: 1
                  }}
                  isRound
                />
              </div>
              <Divider className="my-lg" />
            </>
          )}
          {FormField({
            name: 'full_name',
            helperText: t('e.g. John Smith'),
            placeholder: '',
            required: true
          })}
          {FormField({
            name: 'email',
            helperText: t('Must be valid email address'),
            placeholder: '',
            required: true
          })}
          <div className="p-relative">
            <Button
              variant="link"
              className="auto-generate-password"
              onClick={generateAndDisplayPassword}
            >
              Auto-Generate Password
            </Button>
            {FormField({
              name: 'password',
              type: isPassword ? 'password' : 'text',
              placeholder: '',
              helperText: t('Must have at least 8 characters'),
              autoComplete: 'new-password',
              endAdornment: (
                <Icon
                  iconClass={isPassword ? 'eye-hide' : 'eye'}
                  type="neutral"
                  onClick={togglePasswordState}
                  size="18px"
                  style={{ cursor: 'pointer' }}
                />
              ),
              required: isNew || !accountId
            })}
          </div>
          {FormField({
            name: 'password_confirmation',
            type: isConfirmPassword ? 'password' : 'text',
            placeholder: '',
            helperText: t('Make sure it matches with password field'),
            endAdornment: (
              <Icon
                iconClass={isConfirmPassword ? 'eye-hide' : 'eye'}
                type="neutral"
                onClick={toggleConfirmPasswordState}
                size="18px"
                style={{ cursor: 'pointer' }}
              />
            ),
            required: isNew || !accountId
          })}
          {FormField({
            name: 'job_title',
            helperText: t('e.g Sales Manager'),
            placeholder: ''
          })}
          {FormField({
            name: 'role',
            type: 'select',
            helperText: t("Specify the user's role or leave it as default"),
            placeholder: t('Default Role'),
            options: roles?.map((r) => ({
              value: r.name,
              label: r.name
            })),
            required: true
          })}
          {FormField({
            name: 'telephone',
            helperText: t('Please include country code'),
            placeholder: ''
          })}
          {FormField({
            name: 'mobile',
            helperText: t('Please include country code'),
            placeholder: ''
          })}
          {isNew &&
            moduleName === 'company' &&
            FormField({
              name: 'add_to_contact_book',
              type: 'switch',
              label: t('Add to contact book')
            })}
        </form>
      </div>
    </Dialog>
  )
}

UserAddEdit.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired
}

UserAddEdit.defaultProps = {
  isOpen: true
}

export default (props) => {
  const { moduleName } = props
  return withReduxDataFetch([
    {
      call: () => rolesCall('roles', moduleName ?? 'company'),
      selector: (store) => rolesSelector(store, moduleName ?? 'company')
    }
  ])(UserAddEdit)(props)
}
