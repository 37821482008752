import React, { useState, useEffect } from 'react'
import { Dialog, Tabs, Table, Button, useSnackbar } from 'components/common'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import {
  updatePrefixSuffix,
  filterSection,
  updateLandedPriceRequest
} from 'store/landed-prices/actions'
import { useSearch, usePagination } from 'hooks'
import {
  selectProductPrices,
  selectSingleLandedPrice,
  selectSections,
  selectCosts
} from 'store/landed-prices/selectors'
import AddPrefixSuffix from './modals/AddPrefixSuffix'
import AddCosts from './modals/AddCosts'

const PER_PAGE_PAGINATION = 5
const buttonStyles = { backgroundColor: '#2569a1', borderRadius: 0 }

const PriceConfigure = ({
  isOpen,
  close,
  title = 'This is default modal title',
  catalogueCurrency
}) => {
  const dispatch = useDispatch()
  const productPrices = useSelector(selectProductPrices)
  const priceData = useSelector(selectSingleLandedPrice)
  const sections = useSelector(selectSections)
  const costs = useSelector(selectCosts)

  const addSnackbar = useSnackbar()
  const { t, i18n } = useTranslation()
  const search = useSearch()
  const [currentTabId, setCurrentTabId] = useState(null)
  const [tabsArray, setTabsArray] = useState(null)
  const [modals, setModals] = useState({
    showPrefixSuffix: false,
    showAddCosts: false
  })

  const [prefixSuffixForm, setPrefixSuffixForm] = useState({
    prefix: priceData.prefix,
    suffix: priceData.suffix
  })

  const [displayedItems, setDisplayedItems] = useState([])

  const updatePrefixSuffixForm = (updatedForm) => {
    setPrefixSuffixForm(updatedForm)
  }

  useEffect(() => {
    const tabsArrayTest = [
      { id: null, title: 'All Sections', to: 'all' },
      ...sections.map((item) => {
        const name = item.names.find((translation) => translation[i18n.language])
        const backgroundColor = item.background_color
        return {
          id: item.id,
          bgColor: backgroundColor,
          title: name ? name[i18n.language] : t('Translation not available')
        }
      })
    ]

    setTabsArray(tabsArrayTest)
  }, [sections, i18n.language])

  const convertUnicode = (input) => {
    return input.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) => String.fromCharCode(parseInt(b, 16)))
  }

  const columns = [
    {
      Header: t('Part Number'),
      accessor: 'part_number',
      Cell: ({ value }) =>
        `${priceData.prefix ? priceData.prefix : ''}${value}${
          priceData.suffix ? priceData.suffix : ''
        }`
    },
    {
      Header: `${t('Net Price')} (${priceData.currency.name})`,
      accessor: 'price',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(priceData.currency.symbol)}`
    },
    {
      Header: `${t('Net Price')} (${catalogueCurrency.name})`,
      accessor: 'convertedPrice',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
    },
    {
      Header: `${t('Add Costs')} %`,
      accessor: 'added_costs',
      Cell: ({ value }) => `${parseFloat(value).toFixed(2)} %`
    },
    {
      Header: `${t('Landed Price')} %`,
      accessor: 'landed_price',
      Cell: ({ value }) =>
        `${parseFloat(value).toFixed(2)} ${convertUnicode(catalogueCurrency.symbol)}`
    }
  ]

  const handleToggleModal = (modal) => {
    setModals({
      ...modals,
      [modal]: !modals[modal] // Toggles the state of the specified modal
    })
  }

  const handleSavePrefixSuffix = () => {
    dispatch(updatePrefixSuffix(prefixSuffixForm.prefix, prefixSuffixForm.suffix))
    handleToggleModal('showPrefixSuffix')
  }

  const {
    filteredList: filteredProductPrices,
    SearchBar,
    searchState: { part_number }
  } = search(productPrices, [
    {
      type: 'text',
      keyName: 'part_number',
      condition: 'contains',
      placeholder: 'Search...'
    }
  ])

  const handleSave = () => {
    const transformedData = {
      id: priceData.id,
      suffix: priceData.suffix,
      prefix: priceData.prefix,
      priority: priceData.priority,
      sections: []
    }

    // Create a map to keep track of sections by section_id
    const sectionMap = new Map()

    // Iterate through the second dataset and transform it
    costs.forEach((item) => {
      if (!sectionMap.has(item.section_id)) {
        sectionMap.set(item.section_id, {
          section_id: item.section_id,
          costs: []
        })
      }

      sectionMap.get(item.section_id).costs.push({
        title: item.title,
        type: item.type,
        value: item.value
      })
    })

    transformedData.sections = Array.from(sectionMap.values())

    dispatch(updateLandedPriceRequest(transformedData))
    addSnackbar({
      variant: 'success',
      message: t('Saved Successfully')
    })
    close()
  }

  const { activePage, pagesCount, setActivePage } = usePagination({
    totalCount: filteredProductPrices.length,
    perPage: PER_PAGE_PAGINATION
  })

  const paginateProducts = ({ prodList }) => {
    const startIndex = activePage * PER_PAGE_PAGINATION
    const endIndex = startIndex + PER_PAGE_PAGINATION
    return prodList.slice(startIndex, endIndex)
  }

  useEffect(() => {
    if (activePage >= 0) {
      const nextProductsList = paginateProducts({ prodList: filteredProductPrices })
      setDisplayedItems(nextProductsList)
    }
  }, [activePage, filteredProductPrices])

  const handleTabChange = (tabId) => {
    setCurrentTabId(tabId)
    dispatch(filterSection(tabId))
    setActivePage(0)
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="xl"
      alignment="center"
      hasPagination
      pagesCount={pagesCount}
      setActivePage={setActivePage}
      title={title}
      disableBackdropClick
      actions={[
        {
          text: t('Cancel'),
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: t('Save'),
          variant: 'primary',
          buttonClassname: 'success',
          onClick: handleSave
        }
      ]}
    >
      <div className="price-edit-modal-container">
        {tabsArray && (
          <Tabs defaultTabIndex={0} type={2} onTabClick={handleTabChange}>
            {tabsArray.map((tab, index) => (
              <Tabs.Tab
                key={tab.id || index}
                title={tab.title}
                id={tab.id}
                tabColor={tab.bgColor}
              />
            ))}
          </Tabs>
        )}
        <div className="pricing-table-wrapper">
          <div className="flex flex-align-center">
            {SearchBar}
            <div className="flex flex-justify-space-between flex-align-center w-full px-md">
              <Button
                type="button"
                style={buttonStyles}
                onClick={() => handleToggleModal('showPrefixSuffix')}
              >
                {!priceData?.prefix || !priceData?.suffix
                  ? 'Add Prefix/Suffix'
                  : 'Edit Prefix/Suffix'}
              </Button>
              <Button
                type="button"
                style={buttonStyles}
                onClick={() => handleToggleModal('showAddCosts')}
              >
                Add Costs %
              </Button>
            </div>
          </div>
          <Table data={displayedItems} columns={columns} className="mt-sm" />
          {modals.showPrefixSuffix && (
            <AddPrefixSuffix
              isOpen={modals.showPrefixSuffix}
              close={() => handleToggleModal('showPrefixSuffix')}
              onSave={handleSavePrefixSuffix}
              form={prefixSuffixForm}
              updateForm={updatePrefixSuffixForm}
            />
          )}
          {modals.showAddCosts && (
            <AddCosts
              isOpen={modals.showAddCosts}
              close={() => handleToggleModal('showAddCosts')}
            />
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default PriceConfigure
