import React, { memo } from 'react'
import { Dialog } from 'components/common'
import { useTranslation } from 'react-i18next'

const ConfirmationDialog = memo(
  ({
    isOpen,
    close,
    type,
    typeAdditional,
    onConfirm,
    action,
    isLoading,
    onCancelText,
    onConfirmText
  }) => {
    const submit = () => {
      onConfirm(action.id)
      close()
    }

    const capitalizeFirstCharacter = (inputString) =>
      inputString.charAt(0).toUpperCase() + inputString.slice(1)
    const { t } = useTranslation()

    return (
      <Dialog
        open={isOpen}
        close={close}
        size="xs"
        alignment="center"
        title={capitalizeFirstCharacter(t(type))}
        description={`${t(
          `Are you sure you want to ${type.toLowerCase()} this`
        )} ${typeAdditional}?`}
        disableBackdropClick
        actions={[
          {
            text: t(onCancelText || 'Cancel'),
            buttonClassname: 'danger',
            variant: 'tertiary'
          },
          {
            text: t(onConfirmText || 'Confirm'),
            buttonClassname: 'success',
            variant: 'primary',
            isDestructive: true,
            onClick: submit
          }
        ]}
      />
    )
  }
)

export default ConfirmationDialog
ConfirmationDialog.displayName = 'ConfirmationDialog'
