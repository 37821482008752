/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-constant-condition */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
import React, { memo, useState, useEffect } from 'react'
import moment from 'moment'
import { withDataFetch } from 'components/HOC'
import { axiosRequest } from 'clients/api'
import { useForm } from 'hooks'
import { Dialog, Loader, Typography, Divider, Icon, Field } from 'components/common'
import { useTranslation } from 'react-i18next'
import validationSchema from './validation-schema'

const styles = {
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap'
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: typeof state.data.id === 'string' && '400',
    fontSize: typeof state.data.id === 'string' && '12px'
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    fontSize: '14px',
    color: '#333333',
    minWidth: 'unset'
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    paddingLeft: '3px'
  })
}

const components = {
  MultiValueRemove: () => null,
  IndicatorSeparator: () => null
}

const Send = memo(({ isOpen, close, selectedImage, sendFunction, isLoading, fetchedData }) => {
  const { t } = useTranslation()
  const { branches } = fetchedData.mediaSendOptions

  const [branchesOptions, _] = useState(Array.isArray(branches) ? [...branches] : [])

  const [customerTypesOptions, setCustomerTypesOptions] = useState([])
  const [customerServicesOptions, setCustomerServicesOptions] = useState([])
  const [customerOptions, setCustomerOptions] = useState([])

  const [selectedBranches, setSelectedBranches] = useState([])

  const [selectedCustomers, setSelectedCustomers] = useState([])
  const [selectedRecipients, setSelectedRecipients] = useState([])
  const [selectedCustomerTypes, setSelectedCustomerTypes] = useState([])
  const [selectedCustomerServices, setSelectedCustomerServices] = useState([])

  const [selectedAttachments, setSelectedAttachments] = useState([selectedImage])
  const [selectedFilters, setSelectedFilters] = useState([])

  useEffect(() => {
    setSelectedFilters(() => [
      ...selectedBranches,
      ...selectedCustomerTypes,
      ...selectedCustomerServices
    ])
  }, [selectedBranches, selectedCustomerTypes, selectedCustomerServices])

  const [filteredCustomerOptions, setFilteredCustomerOptions] = useState([...customerOptions])

  useEffect(() => {
    if (
      selectedBranches.length &&
      !selectedCustomerTypes.length &&
      !selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter((customerOpt) =>
          selectedBranches.some((b) => b.id === customerOpt.branch_id)
        )
      )
    }
    if (
      !selectedBranches.length &&
      selectedCustomerTypes.length &&
      !selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter((customerOpt) =>
          selectedCustomerTypes.some((ct) => ct.id === customerOpt.customer_type_id)
        )
      )
    }
    if (
      !selectedBranches.length &&
      !selectedCustomerTypes.length &&
      selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter((customerOpt) =>
          selectedCustomerServices.some((cs) => cs.id === customerOpt.customer_service_id)
        )
      )
    }

    if (
      selectedBranches.length &&
      selectedCustomerTypes.length &&
      selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter(
          (customerOpt) =>
            selectedBranches.some((b) => b.id === customerOpt.branch_id) &&
            selectedCustomerTypes.some((ct) => ct.id === customerOpt.customer_type_id) &&
            selectedCustomerServices.some((cs) => cs.id === customerOpt.customer_service_id)
        )
      )
    }

    if (
      selectedBranches.length &&
      selectedCustomerTypes.length &&
      !selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter(
          (customerOpt) =>
            selectedBranches.some((b) => b.id === customerOpt.branch_id) &&
            selectedCustomerTypes.some((ct) => ct.id === customerOpt.customer_type_id)
        )
      )
    }
    if (
      selectedBranches.length &&
      !selectedCustomerTypes.length &&
      selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter(
          (customerOpt) =>
            selectedBranches.some((b) => b.id === customerOpt.branch_id) &&
            selectedCustomerServices.some((cs) => cs.id === customerOpt.customer_service_id)
        )
      )
    }
    if (
      !selectedBranches.length &&
      selectedCustomerTypes.length &&
      selectedCustomerServices.length
    ) {
      return setFilteredCustomerOptions(() =>
        customerOptions.filter(
          (customerOpt) =>
            selectedCustomerTypes.some((ct) => ct.id === customerOpt.customer_type_id) &&
            selectedCustomerServices.some((cs) => cs.id === customerOpt.customer_service_id)
        )
      )
    }

    return null
  }, [selectedBranches, selectedCustomerTypes, selectedCustomerServices])

  useEffect(() => {
    if (!selectedFilters.length) {
      setFilteredCustomerOptions([...customerOptions])
    }
    return null
  }, [selectedFilters])

  useEffect(() => {
    setSelectedRecipients(() => [...selectedBranches, ...selectedCustomers])
  }, [selectedBranches, selectedCustomers])

  const {
    FormField,
    handleSubmit,
    formState: { isDirty, errors }
  } = useForm({
    validationSchema,
    defaultValues: {},
    isLoading
  })

  const handleDelete = (recipient, section) => {
    if (
      selectedRecipients.some((recip) => recipient === recip) &&
      selectedFilters.some((filt) => recipient === filt)
    ) {
      if (section === 'filter') {
        return setSelectedBranches((prevState) =>
          prevState.filter((recip) => {
            if (recip.name === recipient.name && recip.id === recipient.id) {
              return false
            }
            return true
          })
        )
      }
      return setSelectedRecipients((prevState) =>
        prevState.filter((recip) => {
          if (recip.name === recipient.name && recip.id === recipient.id) {
            return false
          }
          return true
        })
      )
    }
    return (
      setSelectedBranches((prevState) =>
        prevState.filter((recip) => {
          if (recip.name === recipient.name && recip.id === recipient.id) {
            return false
          }
          return true
        })
      ) ||
      setSelectedCustomerTypes((prevState) =>
        prevState.filter((recip) => {
          if (recip.name === recipient.name && recip.id === recipient.id) {
            return false
          }
          return true
        })
      ) ||
      setSelectedCustomerServices((prevState) =>
        prevState.filter((recip) => {
          if (recip.name === recipient.name && recip.id === recipient.id) {
            return false
          }
          return true
        })
      ) ||
      setSelectedCustomers((prevState) =>
        prevState.filter((recip) => {
          if (recip.name === recipient.name && recip.id === recipient.id) {
            return false
          }
          return true
        })
      )
    )
  }

  const handleSelectAll = (e) => {
    const tp = e.find((recip) => typeof recip.id === 'string')
    if (tp.id === '*branches') {
      setSelectedBranches([...branchesOptions])
    }
    if (tp.id === '*customerTypes') {
      setSelectedCustomerTypes([...customerTypesOptions])
    }
    if (tp.id === '*customerServices') {
      setSelectedCustomerServices([...customerServicesOptions])
    }
    if (tp.id === '*customer') {
      setSelectedCustomers([...customerOptions])
    }
  }

  const submit = (sendData) => {
    const recipientIds = selectedRecipients.map((rec) => rec.id)

    const customerTypesOptionsAsString = customerTypesOptions.map((item) => item.name).join(', ')
    const customerServicesOptionsAsString = customerServicesOptions
      .map((item) => item.name)
      .join(', ')

    const finalData = {
      title: sendData.media_send_field_title,
      send_at: moment
        .utc(moment(sendData.media_send_field_date).utc())
        .format('YYYY-MM-DD HH:mm:ss'),
      account_ids: [...recipientIds],
      customer_types: customerTypesOptionsAsString,
      customer_services: customerServicesOptionsAsString,
      media_library_ids: [selectedImage.id]
    }

    sendFunction(finalData)
    close()
  }

  const fetchData = (queryParams) => {
    const queryString = Object.values(queryParams).join('&')

    axiosRequest('get', `/promotion/get-form-data?${queryString}`)
      .then((response) => {
        if (response.data.customer_services.length) {
          let customerServices = []
          customerServices = response.data.customer_services.map((service, index) => {
            return {
              name: service,
              id: index++
            }
          })
          setCustomerServicesOptions(customerServices)
        }

        if (response.data.customer_types.length) {
          setCustomerTypesOptions([...response.data.customer_types])
        }

        if (response.data.customers.length) {
          setCustomerOptions([...response.data.customers])
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      alignment="right"
      title="Send to:"
      disableBackdropClick
      actions={[
        {
          text: 'Cancel',
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: 'Send',
          buttonClassname: 'success',
          variant: 'primary',
          disabled: !selectedRecipients.length,
          onClick: handleSubmit(submit)
        }
      ]}
      className="media-library-send-dialog"
    >
      {isLoading && <Loader linear />}
      <div className="media-library-send-dialog__content container p-md flex">
        <div className="media-library-send-dialog__side-panel p-md">
          <form id="media-library-send-dialog-form">
            {branchesOptions.length > 0 && (
              <>
                <Typography variant="body2">{t('Branches')}</Typography>
                <Field
                  value={selectedBranches}
                  className="media-send-field mt-xs"
                  name="media_send_field_branches"
                  type="select"
                  label={t('Select Branch(es)')}
                  placeholder={t('Select or type to search')}
                  required
                  isSearchable
                  isMulti
                  isClearable
                  closeMenuOnSelect={false}
                  options={[{ id: '*branches', name: t('All Branches') }, ...branchesOptions]}
                  styles={styles}
                  components={components}
                  onChange={(e) => {
                    const allExists = e.some((item) => item.id === '*branches')
                    if (allExists) {
                      handleSelectAll([...e])

                      if (e.length) {
                        fetchData({
                          branch_ids: branchesOptions
                            .map((item) => `branch_ids[]=${item.id}`)
                            .join('&')
                        })
                      }
                    } else {
                      setSelectedBranches([...e])
                      if (e.length) {
                        fetchData({
                          branch_ids: e.map((item) => `branch_ids[]=${item.id}`).join('&')
                        })
                      }
                    }
                  }}
                />
                <Divider className="my-md" />
              </>
            )}
            <Typography variant="body2">{t('Customers')}</Typography>
            <Field
              value={selectedCustomerTypes}
              className="media-send-field mt-xs"
              name="media_send_field_customerTypes"
              type="select"
              label={t('Select Customer Type(s)')}
              placeholder={t('Select or type to search')}
              isSearchable
              isMulti
              closeMenuOnSelect={false}
              options={[
                { id: '*customerTypes', name: t('All Customer Types') },
                ...customerTypesOptions
              ]}
              styles={styles}
              components={components}
              onChange={(e) => {
                const allExists = e.some((item) => item.id === '*customerTypes')
                if (allExists) {
                  handleSelectAll([...e])

                  if (e.length) {
                    fetchData({
                      branch_ids: selectedBranches
                        .map((item) => `branch_ids[]=${item.id}`)
                        .join('&'),
                      customer_type_ids: customerTypesOptions
                        .map((item) => `customer_type_ids[]=${item.id}`)
                        .join('&')
                    })
                  }
                } else {
                  setSelectedCustomerTypes([...e])
                  if (e.length) {
                    fetchData({
                      branch_ids: selectedBranches
                        .map((item) => `branch_ids[]=${item.id}`)
                        .join('&'),
                      customer_type_ids: e.map((item) => `customer_type_ids[]=${item.id}`).join('&')
                    })
                  }
                }
              }}
            />
            <Field
              value={selectedCustomerServices}
              className="media-send-field mt-lg"
              name="media_send_field_customerServices"
              type="select"
              label={t('Select Customer Service(s)')}
              placeholder={t('Select or type to search')}
              isSearchable
              isMulti
              closeMenuOnSelect={false}
              options={[
                { id: '*customerServices', name: t('All Customer Services') },
                ...customerServicesOptions
              ]}
              styles={styles}
              components={components}
              onChange={(e) =>
                e.find((recip) => typeof recip.id === 'string')
                  ? handleSelectAll([...e])
                  : setSelectedCustomerServices([...e])
              }
            />
            <Field
              value={selectedCustomers}
              className="media-send-field mt-lg"
              name="media_send_field_customers"
              type="select"
              label={t('Select Customer(s)')}
              placeholder={t('Select or type to search')}
              required
              isSearchable
              isMulti
              closeMenuOnSelect={false}
              options={[{ id: '*customer', name: t('All Customers') }, ...customerOptions]}
              styles={styles}
              components={components}
              onChange={(e) => {
                const allExists = e.some((item) => item.id === '*customer')
                if (allExists) {
                  handleSelectAll([...e])
                } else {
                  setSelectedCustomers([...e])
                }
              }}
            />
            <Divider className="my-md" />
            <Typography variant="body2">{t('Schedule')}</Typography>
            {FormField({
              name: 'media_send_field_date',
              defaultValue: null,
              type: 'date',
              label: 'Sending time',
              placeholder: 'Now',
              className: 'media-send-field mt-xs'
            })}
          </form>
        </div>
        <div className="media-library-send-dialog__main-section flex flex-column ml-md">
          <Typography variant="body1" className="media-library-send-dialog__content-note my-md">
            {t(
              'To send media files, first select branch(es), then select customer account(s) to whom you want to send files.'
            )}
          </Typography>
          <div className="media-library-send-dialog__title-section p-md">
            {FormField({
              name: 'media_send_field_title',
              defaultValue: null,
              label: t('Title'),
              placeholder: t('Subject'),
              className: 'media-send-field mb-0',
              required: true
            })}
          </div>
          <div className="media-library-send-dialog__files-section p-md mt-md">
            <span className="fw-medium">{t('Attachment')}:</span>
            <div className="media-library-send-dialog__items flex mt-md">
              <div className="media-library-send-dialog__item flex flex-align-center">
                <span className="py-xxs px-xs">{selectedAttachments[0].title}</span>
              </div>
            </div>
          </div>
          <div className="media-library-send-dialog__recipients-section p-md mt-md">
            <span className="fw-medium">{t('Filters')}:</span>
            <div className="media-library-send-dialog__items flex mt-md">
              {selectedFilters.length === 0 ? (
                <Typography variant="body2">
                  {t(
                    'Select branch(es), then select customer account(s) from the left side panel to whom you want to send files.'
                  )}
                </Typography>
              ) : (
                selectedFilters.map((filter) => (
                  <div
                    key={`${filter.id}-${filter.name.replace(/\s/g, '-')}-filter`}
                    className="media-library-send-dialog__item flex flex-align-center"
                  >
                    <span className="py-xxs px-xs">{filter.name}</span>
                    <Icon
                      iconClass="times"
                      type="lighter"
                      size="8px"
                      className="p-sm cursor-pointer"
                      onClick={() => handleDelete(filter, 'filter')}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="media-library-send-dialog__recipients-section p-md mt-md">
            <span className="fw-medium">Recipients:</span>
            <div className="media-library-send-dialog__items flex mt-md">
              {selectedRecipients.length === 0 ? (
                <Typography variant="body2">
                  Select branch(es), then select customer account(s) from the left side panel to
                  whom you want to send files.
                </Typography>
              ) : (
                selectedRecipients.map((recipient) => (
                  <div
                    key={`${recipient.id}-${recipient.name.replace(/\s/g, '-')}-recipient`}
                    className="media-library-send-dialog__item flex flex-align-center"
                  >
                    <span className="py-xxs px-xs">{recipient.name}</span>
                    <Icon
                      iconClass="times"
                      type="lighter"
                      size="8px"
                      className="p-sm cursor-pointer"
                      onClick={() => handleDelete(recipient, 'recipient')}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
})

export default (props) => {
  return withDataFetch([
    {
      method: 'get',
      key: 'mediaSendOptions',
      url: '/promotion/get-form-data'
    }
  ])(Send)(props)
}

Send.displayName = 'Send'
