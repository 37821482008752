import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'hooks'
import { Dialog, Loader } from 'components/common'
import validationSchema from './validation-schema'
import { useTranslation } from 'react-i18next'

const AddEditContact = ({
  accountId,
  isOpen,
  close,
  contactData,
  createAction,
  editAction,
  isLoading,
  isStep
}) => {
  const isNew = !contactData

  const { t } = useTranslation()

  const defaultValues = useMemo(() => {
    if (isNew) {
      return {
        full_name: '',
        email: '',
        department: '',
        job_title: '',
        telephone: '',
        mobile: ''
      }
    }

    const { full_name, email, department, job_title, telephone, mobile } = contactData

    return {
      full_name: full_name || '',
      email: email || '',
      department: department || '',
      job_title: job_title || '',
      telephone: telephone || '',
      mobile: mobile || ''
    }
  }, [])

  const {
    FormField,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    validationSchema,
    defaultValues,
    isLoading
  })

  const submitCreateForm = useCallback((data) => {
    const finalData = {
      ...data,

      ...(!isStep
        ? {
            account_id: Number(accountId)
          }
        : {
            step: 'contact',
            method: null,
            index: null
          })
    }

    createAction(finalData)
  }, [])

  const submitEditForm = useCallback((data) => {
    const finalData = {
      ...data,

      ...(!isStep
        ? {
            account_id: contactData.account_id
          }
        : {
            step: 'contact',
            method: contactData.method,
            index: contactData.index
          })
    }

    isStep ? editAction(finalData) : editAction(finalData, contactData.id)
  }, [])

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="right"
      title={isNew ? t('Create Contact') : t('Edit Contact')}
      disableBackdropClick
      actions={[
        {
          text: t('Cancel'),
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: isNew ? t('Create Contact') : t('Edit Contact'),
          buttonClassname: 'success',
          variant: 'primary',
          onClick: handleSubmit(isNew ? submitCreateForm : submitEditForm)
          // disabled: !isDirty
        }
      ]}
    >
      {isLoading && <Loader linear />}

      <div className="contact-modal container pt-lg pb-xxs px-lg">
        <form id="contact-form">
          {FormField({
            name: 'full_name',
            helperText: t('e.g. John Smith'),
            placeholder: '',
            required: true
          })}
          {FormField({
            name: 'email',
            helperText: t('Must be valid email address'),
            placeholder: '',
            required: true
          })}
          {FormField({
            name: 'department',
            helperText: t('e.g. Sales Department.'),
            placeholder: ''
          })}
          {FormField({
            name: 'job_title',
            helperText: t('e.g Sales Manager'),
            placeholder: ''
          })}
          {FormField({
            name: 'telephone',
            helperText: t('Please include country code'),
            placeholder: ''
          })}
          {FormField({
            name: 'mobile',
            helperText: t('Please include country code'),
            placeholder: ''
          })}
        </form>
      </div>
    </Dialog>
  )
}

AddEditContact.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired
}

AddEditContact.defaultProps = {
  isOpen: false
}

export default AddEditContact
