/* eslint-disable no-param-reassign */
/* eslint-disable no-lonely-if */
/* eslint-disable no-case-declarations */
import * as actionTypes from './actions'

const initialState = {
  carts: [],
  stock: null,
  partNumber: null,
  damagedItems: [],
  removedItems: [],
  totalVatPriceOfItems: 0,
  loading: false,
  cartLoading: false,
  error: null
}

const cartsReducer = (state = initialState, action) => {
  const vatRateToPrice = (price, quantity = 0, vatRate = 0) => {
    const adjustedVatRate = vatRate / 100
    const subtotal = quantity * price
    const vatAmount = subtotal * adjustedVatRate
    const finalPrice = subtotal + vatAmount

    return parseFloat(finalPrice.toFixed(2))
  }

  switch (action.type) {
    case actionTypes.FETCH_CARTS_REQUEST:
      return {
        ...state,
        loading: true,
        cartLoading: true,
        error: null
      }

    case actionTypes.UPDATE_QUANTITY_REQUEST:
    case actionTypes.DELETE_FROM_CART_REQUEST:
    case actionTypes.GET_STOCK_REQUEST:
    case actionTypes.GET_DATA_BY_SECTION_REQUEST:
    case actionTypes.SEARCH_BY_PART_NUMBER_REQUEST:
    case actionTypes.INIT_CATALOG_REQUEST:
    case actionTypes.CHECKOUT_ORDER_REQUEST:
    case actionTypes.ADD_PART_NUMBER_TO_CART_REQUEST:
      return { ...state, loading: true, error: null }

    case actionTypes.FETCH_CARTS_SUCCESS:
      const { data } = action.payload

      const cartsWithVatPrice = data.map((cartItem) => {
        const { price, quantity, vat_rate } = cartItem
        const vatPrice = vatRateToPrice(price?.price ?? 0, quantity, vat_rate?.code)
        return { ...cartItem, vatPrice }
      })

      const totalVatPriceOfItems = cartsWithVatPrice.reduce(
        (total, cartItem) => total + cartItem.vatPrice,
        0
      )

      return {
        ...state,
        carts: cartsWithVatPrice,
        totalVatPriceOfItems,
        loading: false,
        cartLoading: false,
        error: null
      }

    case actionTypes.REMOVE_FROM_CART:
      const removedItemIds = state.removedItems.map((item) => item.id)
      const updatedCartsAfterRemoval = state.carts.filter(
        (cart) => !removedItemIds.includes(cart.id)
      )

      return {
        ...state,
        carts: updatedCartsAfterRemoval,
        // removedItems: [],
        loading: false,
        error: null
      }

    case actionTypes.GET_STOCK_SUCCESS:
      const { stock } = action.payload

      const damagedItemsUpdated = []
      const removedItemsUpdated = []

      let totalPrice = 0;

      const updatedCartsByStock = state.carts.map((cartItem) => {
        const partNumber = cartItem.part_number
        const hubId = cartItem.hub_id

        if (stock[partNumber] && stock[partNumber][hubId]) {
          const { combined_stock, combined_price } = stock[partNumber][hubId]

          // if (combined_stock === 0) {
          //   removedItemsUpdated.push({
          //     id: cartItem.id,
          //     part_number_id: cartItem.part_number_id,
          //     part_number: cartItem.part_number
          //   })
          // } else {
          //   if (cartItem.quantity > combined_stock) {
          //     cartItem.quantity = combined_stock

          //     damagedItemsUpdated.push({
          //       id: cartItem.id,
          //       part_number_id: cartItem.part_number_id,
          //       part_number: cartItem.part_number,
          //       requested_quantity: cartItem.quantity,
          //       available_quantity: combined_stock
          //     })
          //   }
          // }

          totalPrice += (combined_price * cartItem.quantity);

          return {
            ...cartItem,
            combined_stock,
            combined_price
          }
        }
        return cartItem
      })

      return {
        ...state,
        carts: updatedCartsByStock,
        totalVatPriceOfItems: totalPrice,
        damagedItems: damagedItemsUpdated,
        removedItems: removedItemsUpdated,
        loading: false,
        error: null
      }

    case actionTypes.UPDATE_QUANTITY_SUCCESS:
      const { id, updatedQuantity } = action.payload

      const updatedCarts = state.carts.map((cartItem) => {
        if (cartItem.id === id) {
          return {
            ...cartItem,
            quantity: Math.max(1, updatedQuantity)
          }
        }
        return cartItem
      })

      return {
        ...state,
        carts: updatedCarts,
        loading: false,
        error: null
      }

    case actionTypes.DELETE_FROM_CART_SUCCESS:
      return {
        ...state,
        carts: state.carts.filter((cart) => cart.id !== action.payload.id),
        loading: false,
        error: null
      }

    case actionTypes.CHECKOUT_ORDER_SUCCESS:
      return { ...state, damagedItems: action.payload.data.damaged_items }

    case actionTypes.CLEAR_CART:
      return { ...state, carts: [] }
    case actionTypes.CLEAR_DAMAGED_ITEMS:
      return { ...state, damagedItems: [] }

    case actionTypes.CLEAR_REMOVED_ITEMS:
      return { ...state, removedItems: [] }

    case actionTypes.FETCH_CARTS_FAILURE:
      return { ...state, error: action.payload, cartLoading: false, loading: false }

    case actionTypes.ADD_PART_NUMBER_TO_CART_FAILURE:
      return { ...state, error: action.payload, cartLoading: false, loading: false }

    case actionTypes.CHECKOUT_ORDER_FAILURE:
    case actionTypes.UPDATE_QUANTITY_FAILURE:
    case actionTypes.DELETE_FROM_CART_FAILURE:
    case actionTypes.GET_STOCK_FAILURE:
    case actionTypes.GET_DATA_BY_SECTION_FAILURE:
    case actionTypes.SEARCH_BY_PART_NUMBER_FAILURE:
    case actionTypes.INIT_CATALOG_FAILURE:
      return { ...state, error: action.payload, loading: false }

    case actionTypes.CLEAR_STATE:
      if (!action.stateProperty) {
        return initialState
      }
      return { ...state, [action.stateProperty]: null }

    default:
      return state
  }
}

export default cartsReducer
