import React, { memo, useRef } from 'react'
import { Dialog, Loader, Dropzone, useSnackbar } from 'components/common'

const MediaUpload = memo(({ isOpen, close, uploadFunction, isLoading }) => {
  const dropzoneRef = useRef()
  const addSnackbar = useSnackbar()

  const submitFiles = () => {
    const files = dropzoneRef.current.getFiles()

    if (files?.[0]) {
      uploadFunction(files[0])
      close()
    } else {
      addSnackbar({
        variant: 'warning',
        message: 'Please select file first'
      })
    }
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="center"
      title="Upload Files"
      disableBackdropClick
      actions={[
        {
          text: 'Cancel',
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: 'Upload',
          buttonClassname: 'success',
          variant: 'primary',
          onClick: submitFiles
        }
      ]}
      className="media-library-upload-dialog"
    >
      {isLoading && <Loader linear />}
      <Dropzone
        ref={dropzoneRef}
        title="Drag and drop your image file here"
        subTitle="to choose your image file"
        config={{
          acceptedFiles: ['image/jpg', 'image/jpeg', 'image/png'],
          maxFiles: 1,
          singleFileMaxSizeMB: 1
        }}
      />
    </Dialog>
  )
})

export default MediaUpload
MediaUpload.displayName = 'MediaUpload'
