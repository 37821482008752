/* eslint-disable require-yield */
import { call, put, fork, takeLatest } from 'redux-saga/effects'
import { axiosSagaRequest } from 'clients/api'
import { errorAdd } from 'store/error/actions'
import * as actions from './actions'

function* requestWithActions(actionType, apiRequest, successAction, failureAction) {
  try {
    const response = yield call(apiRequest)
    yield put(successAction(response.data))
  } catch (error) {
    yield put(failureAction(error.message))
    yield put(errorAdd(error, actionType))
  }
}

function* getStock(params) {
  try {
    const stockResponse = yield call(() =>
      // axiosSagaRequest('post', 'web-catalogue/get-stock', {
      axiosSagaRequest('post', 'web-catalogue/cart/get-cart-hub-stock', {
        hubIds: params
      })
    )
    const stockData = stockResponse.data

    yield put(actions.getStockSuccess(stockData))
  } catch (error) {
    yield put(actions.getStockFailure(error.message))
    yield put(errorAdd(error, actions.GET_STOCK_REQUEST))
  }
}

function* fetchCarts() {
  try {
    const response = yield call(() => axiosSagaRequest('get', 'web-catalogue/cart/get-cart'))

    const { data } = response.data

    // const partNumberIds = data.map((cart) => cart.part_number_id)
    const hubToPartNumberMap = data.reduce((cart, item) => {
      if (item.hub_id !== null) {
        if (!cart[item.hub_id]) {
          cart[item.hub_id] = []
        }
        cart[item.hub_id].push(item.part_number)
      }
      return cart
    }, {})

    yield fork(getStock, hubToPartNumberMap)
    yield put(actions.fetchCartsSuccess(response.data))
  } catch (error) {
    yield put(actions.fetchCartsFailure(error.message))
    yield put(errorAdd(error, actions.FETCH_CARTS_REQUEST))
  }
}

function* updateQuantity(action) {
  const { id, updatedQuantity } = action.payload

  yield requestWithActions(
    actions.UPDATE_QUANTITY_REQUEST,
    () =>
      axiosSagaRequest('put', `web-catalogue/cart/toggle-quantity/${id}`, {
        quantity: updatedQuantity
      }),
    () => actions.updateQuantitySuccess({ id, updatedQuantity }),
    actions.updateQuantityFailure
  )
}

function* deleteFromCart(action) {
  const { id } = action.payload

  yield requestWithActions(
    actions.DELETE_FROM_CART_REQUEST,
    () => axiosSagaRequest('delete', `web-catalogue/cart/remove-from-cart/${id}`),
    () => actions.deleteFromCartSuccess({ id }),
    actions.deleteFromCartFailure
  )
}

function* checkoutOrder(action) {
  yield requestWithActions(
    actions.CHECKOUT_ORDER_REQUEST,
    () => axiosSagaRequest('post', 'web-catalogue/orders', action.payload),
    actions.checkoutOrderSuccess,
    actions.checkoutOrderFailure
  )
}

function* addPartNumberToCart(action) {
  const { partNumber } = action.payload

  try {
    const response = yield call(() =>
      axiosSagaRequest('post', 'web-catalogue/cart/add-part-number', { part_number: partNumber })
    )

    const { data } = response.data
    const partNumberIds = data.map((cart) => cart.part_number_id)

    yield fork(getStock, partNumberIds)
    yield put(actions.addPartNumberToCartSuccess(response.data))
  } catch (error) {
    yield put(actions.addPartNumberToCartFailure(error.message))
    yield put(errorAdd(error, actions.ADD_PART_NUMBER_TO_CART_REQUEST))
  }
}

export default function* cartsWatcherSaga() {
  yield takeLatest(actions.FETCH_CARTS_REQUEST, fetchCarts)
  yield takeLatest(actions.UPDATE_QUANTITY_REQUEST, updateQuantity)
  yield takeLatest(actions.DELETE_FROM_CART_REQUEST, deleteFromCart)
  yield takeLatest(actions.GET_STOCK_REQUEST, getStock)
  yield takeLatest(actions.CHECKOUT_ORDER_REQUEST, checkoutOrder)
  yield takeLatest(actions.ADD_PART_NUMBER_TO_CART_REQUEST, addPartNumberToCart)
}
