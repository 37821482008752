import { createPromiseAction } from '@adobe/redux-saga-promise'

export const VEHICLE_DATA_GET = 'VEHICLE_DATA_GET'
// export const ACCOUNT_GET = 'ACCOUNT_GET's
// export const ACCOUNT_ADD_PROMISE = 'ACCOUNT_ADD_PROMISE'
// export const ACCOUNT_EDIT_PROMISE = 'ACCOUNT_EDIT_PROMISE'
// export const ACCOUNT_STATUS_PROMISE = 'ACCOUNT_STATUS_PROMISE'
// export const ACCOUNT_DELETE_PROMISE = 'ACCOUNT_DELETE_PROMISE'
export const SEARCH_LOADING_SWITCH = 'SEARCH_LOADING_SWITCH'

// export const accountAddPromise = createPromiseAction(ACCOUNT_ADD_PROMISE)
// export const accountEditPromise = createPromiseAction(ACCOUNT_EDIT_PROMISE)
// export const accountStatusPromise = createPromiseAction(ACCOUNT_STATUS_PROMISE)
// export const accountDeletePromise = createPromiseAction(ACCOUNT_DELETE_PROMISE)

export function getVehiclesData(moduleName, options) {
  return { type: VEHICLE_DATA_GET, payload: { moduleName, options } }
}

// export function accountGet(moduleName, id, body) {
//   return { type: ACCOUNT_GET, payload: { moduleName, id, body } }
// }

export function searchLoadingSwitch(moduleName, status) {
  return { type: SEARCH_LOADING_SWITCH, payload: { moduleName, status } }
}

const createAction = (type) => {
  return (payload) => ({ type, payload })
}

export const PRODUCTS_BY_VEHICLE_GET = 'PRODUCTS_BY_VEHICLE_GET'
export const GROUPS_BY_REGNUMBER_GET = 'GROUPS_BY_REGNUMBER_GET'
export const GROUPS_BY_VINNUMBER_GET = 'GROUPS_BY_VINNUMBER_GET'
export const GROUPS_BY_PARTNUMBER_GET = 'GROUPS_BY_PARTNUMBER_GET'
export const FETCH_NON_AUTO_GROUPS_REQUEST = 'FETCH_NON_AUTO_GROUPS_REQUEST'
export const TOGGLE_GROUP = 'TOGGLE_GROUP'
export const TOGGLE_SECTION = 'TOGGLE_SECTION'
export const CLEAR_SECTION = 'CLEAR_SECTION'
export const CLEAR_DATA_FROM_STORE = 'CLEAR_DATA_FROM_STORE'
export const SEARCH_FILTER_TOGGLE = 'SEARCH_FILTER_TOGGLE'
export const SEARCH_FILTER_BRAND_TOGGLE = 'SEARCH_FILTER_BRAND_TOGGLE'
export const AXLES_FILTER_TOGGLE = 'AXLES_FILTER_TOGGLE'
export const GET_STOCK_REQUEST = 'GET_STOCK_REQUEST'
export const RESET_VRM_NOTIFY = 'RESET_VRM_NOTIFY'
export const VRM_INCORRECT_NOTIFY = 'VRM_INCORRECT_NOTIFY'

export const RESET_STATE = 'RESET_STATE'

export const fetchNonAutoGroupsRequest = createAction(FETCH_NON_AUTO_GROUPS_REQUEST)
export const fetchGroupsByPartNumberRequest = createAction(GROUPS_BY_PARTNUMBER_GET)
export const groupsByRegNumberNumber = createAction(GROUPS_BY_REGNUMBER_GET)
export const groupsByVinNumberGet = createAction(GROUPS_BY_VINNUMBER_GET)
export const productsByVehicleIdsGet = createAction(PRODUCTS_BY_VEHICLE_GET)

export const clearDataFromStore = createAction(CLEAR_DATA_FROM_STORE)
export const toggleGroup = createAction(TOGGLE_GROUP)
export const toggleSection = createAction(TOGGLE_SECTION)
export const getStockRequest = createAction(GET_STOCK_REQUEST)
export const clearSection = createAction(CLEAR_SECTION)
export const resetVRMNotify = createAction(RESET_VRM_NOTIFY)
export const vrmIncorrectNotify = createAction(VRM_INCORRECT_NOTIFY)

export const searchFilterToggle = createAction(SEARCH_FILTER_TOGGLE)
export const searchFilterBrandToggle = createAction(SEARCH_FILTER_BRAND_TOGGLE)
export const axlesFilterToggle = createAction(AXLES_FILTER_TOGGLE)

export const resetState = createAction(RESET_STATE)
