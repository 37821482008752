import React, { useState } from 'react'
import { Dialog, Input } from 'components/common'
import { useTranslation } from 'react-i18next'

export default function AddPrefixSuffix({ isOpen, close, onSave, form, updateForm }) {
  const { t } = useTranslation()

  const handleSave = () => {
    onSave() // Call the provided onSave function
    updateForm(form) // Send the updated form data to the parent
  }

  const handlePrefixChange = (event) => {
    const updatedForm = {
      ...form,
      prefix: event.target.value
    }
    updateForm(updatedForm)
  }

  const handleSuffixChange = (event) => {
    const updatedForm = {
      ...form,
      suffix: event.target.value
    }
    updateForm(updatedForm)
  }

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="xs"
      alignment="center"
      title="Add Prefix/Suffix"
      disableBackdropClick
      actions={[
        {
          text: t('Cancel'),
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: t('save'),
          buttonClassname: 'success',
          variant: 'primary',
          onClick: handleSave // Call handleSave when Save is clicked
        }
      ]}
    >
      <div className="add-prefix-suffix-wrapper">
        <Input
          name="Prefix"
          type="text"
          value={form.prefix}
          placeholder={t('enterPrefix')}
          onChange={handlePrefixChange} // Handle prefix field change
        />
        <Input
          name="Suffix"
          type="text"
          value={form.suffix}
          placeholder={t('enterSuffix')}
          onChange={handleSuffixChange} // Handle suffix field change
        />
      </div>
    </Dialog>
  )
}
