/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useMemo } from 'react'
import { Dialog, Button, Icon, Input, Select, useSnackbar } from 'components/common'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCurrentCosts,
  selectSectionId,
  selectSingleLandedPrice
} from 'store/landed-prices/selectors'
import { v4 as uuidv4 } from 'uuid'
import { updateCosts } from 'store/landed-prices/actions'

import { useTranslation } from 'react-i18next'

const tableCellStyles = {
  borderBottom: '1px solid #dedede',
  width: '33.3%'
}

const tableHeaders = ['Description', 'Type', '%', '']
const totalTypes = ['Margin', 'Mark Up']

const options = [
  {
    id: 'margin',
    name: 'Margin'
  },
  {
    id: 'markup',
    name: 'Mark Up'
  }
]

const buttonStyles = { backgroundColor: '#2569a1', borderRadius: 0 }

export default function AddCosts({ isOpen, close }) {
  const { t } = useTranslation()
  const addSnackbar = useSnackbar()
  const dispatch = useDispatch()

  const costs = useSelector(selectCurrentCosts)
  const sectionId = useSelector(selectSectionId)
  const singleLandedPrice = useSelector(selectSingleLandedPrice)

  const [costsData, setCostsData] = useState(costs)
  const [validationErrors, setValidationErrors] = useState({})

  // const descriptionInputRefs = useMemo(() => costsData.map(() => React.createRef()), [costsData])

  const handleUpdateCosts = () => {
    const hasEmptyTitle = costsData.some((item) => !item.title)
    const hasEmptyType = costsData.some((item) => !item.type)
    const hasEmptyValue = costsData.some((item) => !item.value)

    // Step 2: Update the validationErrors state
    const newValidationErrors = {}
    if (hasEmptyTitle) newValidationErrors.title = true
    if (hasEmptyType) newValidationErrors.type = true
    if (hasEmptyValue) newValidationErrors.value = true
    setValidationErrors(newValidationErrors)

    if (hasEmptyTitle || hasEmptyType || hasEmptyValue) {
      let errorMessage = `${t('Please fill the following fields')}:`
      if (hasEmptyTitle) errorMessage += ` ${t('Title')}`
      if (hasEmptyType) errorMessage += ` ${t('Type')}`
      if (hasEmptyValue) errorMessage += ` ${t('Value')}`

      addSnackbar({
        variant: 'danger',
        message: errorMessage
      })
    } else {
      dispatch(updateCosts(costsData))
      close()
      addSnackbar({
        variant: 'success',
        message: 'Saved Successfully'
      })
    }
  }

  const actions = [
    {
      text: t('Cancel'),
      buttonClassname: 'danger',
      variant: 'tertiary'
    },
    {
      text: t('save'),
      buttonClassname: 'success',
      variant: 'primary',
      onClick: () => handleUpdateCosts()
      // disabled: costsData.length === 0
    }
  ]

  const handleDeleteCost = (cost) => {
    setCostsData(costsData.filter((item) => item.id !== cost.id))
  }

  const handleAddCost = () => {
    const uuid = uuidv4()

    const newCost = {
      id: uuid,
      landed_price_id: singleLandedPrice.id,
      section_id: sectionId,
      title: '',
      type: '',
      value: ''
    }

    setCostsData([newCost, ...costsData])
  }

  const handleSelectChange = (value, costId) => {
    const updatedCostType = costsData.map((cost) => {
      if (cost.id === costId) {
        return {
          ...cost,
          type: value
        }
      }
      return cost
    })

    setCostsData(updatedCostType)
  }

  const handleInputChange = (value, costId, field) => {
    const updatedCostsField = costsData.map((cost) => {
      if (cost.id === costId) {
        return {
          ...cost,
          [field]: value
        }
      }
      return cost
    })

    setCostsData(updatedCostsField)
  }

  const totalMarkup = costsData
    .filter((cost) => cost.type === 'markup')
    .reduce((total, cost) => total + parseFloat(cost.value || 0), 0)

  const totalMargin = costsData
    .filter((cost) => cost.type === 'margin')
    .reduce((total, cost) => total + parseFloat(cost.value || 0), 0)

  // React.useEffect(() => {
  //   if (descriptionInputRefs[0]?.current) descriptionInputRefs[0].current.focus()
  // }, [costsData])

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="sm"
      alignment="center"
      title="Additional Costs"
      disableBackdropClick
      actions={actions}
    >
      <div className="p-sm">
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr className="table__head">
              {tableHeaders.map((header, index) => (
                <th className="table__cell" key={header || index} style={{ textAlign: 'start' }}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {costsData.map((row, i) => (
              <tr className="table__row" key={row.id}>
                <td className="table__cell" style={tableCellStyles}>
                  <Input
                    name={row.title}
                    type="text"
                    // eslint-disable-next-line no-return-assign
                    // innerRef={descriptionInputRefs[i]}
                    placeholder={t('Description')}
                    value={row.title}
                    onChange={(e) => handleInputChange(e.target.value, row.id, 'title')}
                    error={validationErrors.title}
                  />
                </td>
                <td className="table__cell" style={tableCellStyles}>
                  <Select
                    handleChange={(value) => handleSelectChange(value, row.id)}
                    selectedId={row.type}
                    options={options}
                    defaultValue="Select an option"
                    error={validationErrors.type}
                  />
                </td>
                <td className="table__cell" style={tableCellStyles}>
                  <Input
                    name={row.value}
                    type="number"
                    placeholder="%"
                    value={row.value}
                    onChange={(e) => handleInputChange(parseFloat(e.target.value), row.id, 'value')}
                    error={validationErrors.value}
                  />
                </td>
                <td className="table__cell" style={tableCellStyles}>
                  <div className="add-cost-trash-button" onClick={() => handleDeleteCost(row)}>
                    <Icon iconClass="icon icon--dark icon-trash-delete" type="dark" size="16px" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex flex-justify-end flex-align-center my-sm">
          <Button type="button" style={buttonStyles} onClick={handleAddCost}>
            Add Cost
          </Button>
        </div>

        {totalTypes.map((type, index) => (
          <div key={type || index} className="total-wrapper mb-sm">
            <div className="total-container">Total</div>
            <div className="total-container">{type === 'Margin' ? 'Margin' : 'Mark Up'}</div>
            <div className="total-container">{type === 'Margin' ? totalMargin : totalMarkup}</div>
            <div className="total-container">%</div>
          </div>
        ))}
      </div>
    </Dialog>
  )
}
