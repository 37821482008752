/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Icon, Pagination } from 'components/common'
import { v4 as uuidv4 } from 'uuid'
import { DialogContext } from './dialog-context'

/**
 * Dialog component
 * @param {Boolean} open - Dialog state
 * @param {Function} close - Function to close dialog
 * @param {String} type - Dialog Type ['dialog', 'info', 'success', 'warning', 'danger']
 * @param {String} size - Dialog size ['xxs', 'xs', 'sm', 'smd', 'md', 'lg', 'xl', 'xxl']
 * @param {String} alignment - Dialog alignment type ['right', 'center', 'left']
 * @param {String} title - Dialog title
 * @param {String} description - Dialog description
 * @param {Boolean} disableBackdropClick - Disable backdrop click
 * @param {Array} actions - Dialog buttons
 */

const icons = {
  info: 'information',
  success: 'checkmark',
  warning: 'exclamation-cog',
  danger: 'Exclamation-Triangle'
}

const Dialog = ({
  open,
  close,
  type,
  size,
  alignment,
  title,
  description,
  className,
  modalClassName,
  disableBackdropClick,
  actions,
  actionsLeftSection,
  hasHeader,
  children,
  hasPagination,
  pagesCount,
  setActivePage
}) => {
  const dialogId = useMemo(() => uuidv4(), [])
  const [closing, setClosing] = useState(false)

  const { addDialog, removeDialog } = useContext(DialogContext)

  const icon = icons[type]

  const handleClose = useCallback(() => {
    setClosing(true)
    setTimeout(() => {
      close && close()
      setClosing(false)
    }, 320)
  }, [close])

  const handleActionClick = useCallback((e, text, actionHandler) => {
    if (actionHandler) {
      actionHandler(e)
    } else {
      handleClose()
    }
  }, [])

  // Toggle to context visible dialogs list
  useEffect(() => {
    if (open) {
      addDialog(dialogId)
    } else {
      removeDialog(dialogId)
    }
  }, [open])

  // Remove dialog from visible list on unmount
  useEffect(
    () => () => {
      removeDialog(dialogId)
    },
    []
  )

  return ReactDOM.createPortal(
    open ? (
      <div
        className={classNames(
          'dialog',
          `dialog--${type}`,
          `dialog-alignment--${alignment}`,
          { 'dialog--is-closing': closing },
          { 'dialog--open': open },
          { [className]: className }
        )}
      >
        <div
          className="dialog__backdrop"
          {...(!disableBackdropClick && close && { onClick: handleClose })}
        />
        <div className={`dialog__dialog dialog__dialog--${size} ${modalClassName || ''}`}>
          {hasHeader && (
            <div className="dialog__header">
              <div className="dialog__header__content">
                {icon && (
                  <div className="dialog__header__icon">
                    <Icon iconClass={icon} size="36px" />
                  </div>
                )}
                <span className="dialog__title">{title}</span>
              </div>
              {/* {close && (
                <button className="dialog__close" type="button" title="Close" onClick={handleClose}>
                  <Icon iconClass="close" type="dark" size="12px" />
                </button>
              )} */}
            </div>
          )}
          <div className="dialog__content">
            {description && <p className="dialog__description">{description}</p>}
            {children && children}
          </div>

          {actions.length > 0 && (
            <div
              className={`dialog__actions ${actionsLeftSection && 'flex-justify-space-between'}`}
            >
              {actionsLeftSection && (
                <span className="dialog__actions-left-section">{actionsLeftSection}</span>
              )}
              <div className="flex flex-align-center flex-justify-space-between w-full">
                <div className="flex">
                  {hasPagination && pagesCount > 1 && (
                    <Pagination pagesCount={pagesCount} setActivePage={setActivePage} />
                  )}
                </div>
                <div className="flex">
                  {actions.map((action, index) => (
                    <Button
                      key={index}
                      type={action?.type}
                      variant={
                        action.buttonClassname
                          ? `${action.variant}--${action.buttonClassname}`
                          : action.variant || 'primary'
                      }
                      size={action.size || 'small'}
                      onClick={(e) => handleActionClick(e, action.text, action?.onClick)}
                      className="ml-xs"
                      disabled={action?.disabled}
                    >
                      {action.text}
                    </Button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    ) : null,
    document.body
  )
}

Dialog.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  type: PropTypes.oneOf(['modal', 'info', 'success', 'warning', 'danger']),
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'smd', 'md', 'lg', 'xl', 'xxl']),
  alignment: PropTypes.oneOf(['right', 'center', 'left']),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.node
  ]),
  disableBackdropClick: PropTypes.bool,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      type: PropTypes.string,
      onClick: PropTypes.func
    })
  ),
  actionsLeftSection: PropTypes.string,
  hasHeader: PropTypes.bool
}

Dialog.defaultProps = {
  open: false,
  close: undefined,
  type: 'modal',
  size: 'md',
  alignment: 'center',
  title: '',
  description: undefined,
  className: '',
  children: undefined,
  disableBackdropClick: false,
  actions: [],
  actionsLeftSection: '',
  hasHeader: true
}

export default Dialog
