import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/common'
// import { CartAddByPartNumber } from 'components/complex'

import './index.css'

export default function CartOrdersEmpty({ title }) {
  const { t } = useTranslation()
  const history = useNavigate()

  const handleBack = () => {
    history('/catalogue')
  }

  return (
    <div className="cart-orders-empty-wrapper">
      <h1 className="mb-xl">{title}</h1>
      {/* <div className="mb-xl">
        <CartAddByPartNumber />
      </div> */}
      <Button style={{ backgroundColor: '#263355', borderRadius: 3 }} onClick={handleBack}>
        {t('Back To Catalogue')}
      </Button>
    </div>
  )
}
