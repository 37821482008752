import React from 'react'
import { Table } from 'components/common'
import { useTranslation } from 'react-i18next'

export default function Listing({ orderDetails }) {
  const { t } = useTranslation()

  const columns = [
    {
      Header: t('Part Number'),
      accessor: 'part_number.part_number',
      width: 50
    },
    {
      Header: t('Lookup'),
      accessor: 'lookup',
      width: 50
    },
    {
      Header: t('Location'),
      accessor: 'hub.title',
      width: 50
    },
    {
      Header: t('Description'),
      accessor: 'part_number.description',
      width: 50
    },
    {
      Header: t('QTY'),
      accessor: 'quantity',
      width: 50
    },
    {
      Header: t('Unit Price'),
      accessor: 'price',
      width: 50
    },
    {
      Header: t('Total Price'),
      accessor: 'total_price',
      width: 50
    }
  ]

  return (
    <div>
      <Table columns={columns} data={orderDetails} />
    </div>
  )
}
