import React, { memo } from 'react'
import { Dialog } from 'components/common'
import { useTranslation } from 'react-i18next'

const AccountDelete = memo(({ isOpen, close, type, deleteFunction, action, isLoading }) => {
  const submit = () => {
    deleteFunction(action.id)
    close()
  }

  const capitalizeFirstCharacter = (inputString) =>
    inputString.charAt(0).toUpperCase() + inputString.slice(1)
  const { t } = useTranslation()

  return (
    <Dialog
      open={isOpen}
      close={close}
      size="xs"
      alignment="center"
      title={capitalizeFirstCharacter(t('Delete'))}
      description={`${t('Are you sure you want to delete this')} ${type.toLowerCase()}?`}
      disableBackdropClick
      actions={[
        {
          text: t('Cancel'),
          buttonClassname: 'danger',
          variant: 'tertiary'
        },
        {
          text: t('Delete'),
          buttonClassname: 'success',
          variant: 'primary',
          isDestructive: true,
          onClick: submit
        }
      ]}
    />
  )
})

export default AccountDelete
AccountDelete.displayName = 'AccountDelete'
